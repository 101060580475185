import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./index.css";
import "./who-we-help.css";
import Nav from "../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact";
import Hero from "../components/hero-standard"
import Footer from "../components/footer";
import imgOperator from "../images/gfx/who-we-help-operator.jpg"
import imgCollege from "../images/gfx/who-we-help-college.jpg"
import imgSchool from "../images/gfx/who-we-help-school.jpg"
import imgWork from "../images/gfx/who-we-help-work.jpg"
import imgPhone from "../images/gfx/who-we-help-phone.png"
import imgArrow from "../images/gfx/who-we-help-arrow.png"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Who we help" />
        <Nav activeTab="who-we-help" />
        <div>
            <div>

              <Hero h1="Who we help" h2="Operators, Schools, Colleges & Businesses" />

              <div className="who-operator__parent">
                <div className="who-operator">
                  <div className="who-operator__text">
                    <h2>Operators</h2>
                    <h3>The affordable, digital bus pass system that keeps you in control</h3>
                    <p>ShuttleID helps transport operators remove the manual tasks involved with issuing or selling bus passes and tickets on school, college or work services. Operators that use ShuttleID typically save hundreds of admin hours per year and are able to offer unrivalled convenience and safety to customers. Tender-winning technology that is both simple and affordable.</p>
                    <div>
                      <Link style={{ marginRight: 10 }} to="/packages/" className="btn">Packages</Link>
                      <Link to="/book-demo/" className="btn">Book a demo</Link>
                    </div>
                  </div>
                  <div className="who-operator__img">
                    <img src={imgOperator} alt="" />
                  </div>

                </div>

              </div>

              <div className="who-school__parent">
                <div className="who-school">
                  <div className="who-school__img">
                    <img src={imgSchool} alt="" />
                  </div>
                  <div className="who-school__text">
                    <h2>Schools</h2>
                    <h3>Giving parents and schools peace of mind</h3>
                    <p>A system that not only significantly reduces the admin of running a school bus service but gives parents and schools peace of mind. Safeguarding tools such as passenger scan logs, service alerts and live vehicle tracking offer an unbeaten level of service.</p>
                    <div>
                      <Link style={{ marginRight: 10 }} to="/packages/" className="btn">Packages</Link>
                      <Link to="/book-demo/" className="btn">Book a demo</Link>
                    </div>
                  </div>
                </div>

              </div>

              <div className="who-college__parent">
                <div className="who-college">
                  <div className="who-college__text">
                    <h2>Colleges</h2>
                    {/* <h3>Perfect for colleges, where attendance can vary each day</h3> */}
                    {/* <h3>Increase attendance. Maximise revenue. Reduce admin.</h3> */}
                    <h3>A system that makes complex student travel a breeze</h3>
                    {/* <p>ShuttleID offers a range of ticket and pass types which makes it perfect for use in college settings, where course timetables can vary each day. Ensures vehicles are never overloaded and only the right size vehicle is allocated to save you money.</p> */}
                    <p>ShuttleID offers a range of ticket and pass types which makes it perfect for use in college settings, where course length, timetables and funding varies per student. Ensures vehicles are never overloaded and only the right size vehicle is allocated to save you money. All whilst reducing the admin needed to manage it.</p>
                    <div>
                      <Link style={{ marginRight: 10 }} to="/packages/" className="btn">Packages</Link>
                      <Link to="/book-demo/" className="btn">Book a demo</Link>
                    </div>
                  </div>
                  <div className="who-college__img">
                    <img src={imgCollege} alt="" />
                  </div>

                </div>

              </div>

              <div className="who-school__parent">
                <div className="who-school">
                  <div className="who-school__img">
                    <img src={imgWork} alt="" />
                  </div>
                  <div className="who-school__text">
                    <h2>Businesses</h2>
                    <h3>Helping to get your staff to work on time, every time</h3>
                    <p>More businesses are realising the benefits of their own direct bus service to the workplace. ShuttleID offers staff a convenient bus pass on their phone, along with service alerts and live vehicle tracking to help get them into work on time, every time.</p>
                    <div>
                      <Link style={{ marginRight: 10 }} to="/packages/" className="btn">Packages</Link>
                      <Link to="/book-demo/" className="btn">Book a demo</Link>
                    </div>
                  </div>
                </div>

              </div>

              <div className="who-footer__parent">
                <div className="who-footer">
                  <div className="who-footer__img">
                    <img src={imgPhone} alt="" />
                  </div>
                  <div className="who-footer__text">
                    <h3>Find out more about how our digital bus pass system gives you control and helps you save time and money</h3>
                    <p><Link className="who-footer__btn" to="/packages/">View all packages <img src={imgArrow} alt="" /></Link></p>
                  </div>
                </div>
              </div>

              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
